import useCsvPlugin from "@usecsv/js";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    importerKey: String,
    formAuthenticityToken: String,
  };

  validateDataAndReturnErrors(rows) {
    return new Promise((resolve, reject) => {
      return fetch(
        `/settings/configuration/unit_of_measure_conversions/validate_csv.json`,
        {
          method: "POST",
          body: JSON.stringify({ rows }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "X-CSRF-Token": this.formAuthenticityTokenValue,
          },
        },
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Validation failed");
          }
        })
        .then((data) => {
          console.log(data);
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  launch() {
    useCsvPlugin({
      importerKey: this.importerKeyValue,
      user: { userId: "12345" },
      metadata: {},
      onData: (data) => {
        fetch(`/settings/configuration/unit_of_measure_conversions/import`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "X-CSRF-Token": this.formAuthenticityTokenValue,
          },
        });
      },
      onClose: () => {
        window.location.href = `/settings/configuration/unit_of_measure_conversions`;
        console.log("Importer is closed");
      },
      onRecordsInitial: ({
        uploadId,
        fileName,
        importerId,
        batch,
        user,
        metadata,
        rows,
      }) => {
        return this.validateDataAndReturnErrors(rows)
          .then((results) => {
            const arr = results.filter((data) => !!data);

            if (arr.length > 0) {
              return arr;
            } else {
              return;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      },
      onRecordEdit: ({
        uploadId,
        fileName,
        importerId,
        user,
        metadata,
        row,
      }) => {
        return this.validateDataAndReturnErrors(row)
          .then((results) => {
            const arr = results.filter((data) => !!data);

            if (arr.length > 0) {
              return arr[0];
            } else {
              return;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      },
    });
  }
}

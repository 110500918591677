import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "shelfLifePeriodText"];

  connect() {
    this.sync();
  }

  sync() {
    const selectedPeriod = this.selectTarget.value;
    this.shelfLifePeriodTextTarget.innerText = selectedPeriod || "N/A";
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["versionInput"];

  connect() {
    // Initialize the form based on default selection
    this.toggleVersionInput({
      target: this.element.querySelector(
        'input[name="version_option"]:checked',
      ),
    });
  }

  toggleVersionInput(event) {
    const versionNameInput = this.versionInputTarget.querySelector(
      'input[name="new_version_name"]',
    );

    if (event.target.value === "new_version") {
      this.versionInputTarget.classList.remove("d-none");
      versionNameInput.required = true;
      versionNameInput.disabled = false;
    } else {
      this.versionInputTarget.classList.add("d-none");
      versionNameInput.required = false;
      versionNameInput.disabled = true;
    }
  }
}

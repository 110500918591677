import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["startDate", "endDate", "materialSelect", "warehouseA", "warehouseB", "tableBody"];

  connect() {
    this.validateWarehouseSelection();
  }

  fetchWarehouses() {
    const materialId = this.materialSelectTarget.value;
    if (!materialId) return;

    this.tableBodyTarget.innerHTML = "";
    fetch(`/analytics/fetch_warehouses?material_id=${materialId}`)
      .then((response) => response.json())
      .then((data) => { 
        this.populateSelect(this.warehouseATarget, data);
        this.populateSelect(this.warehouseBTarget, data);
      });
  }

  validateWarehouseSelection() {
    const validate = () => {
      const selectedA = this.warehouseATarget.value;
      const selectedB = this.warehouseBTarget.value;

      if (selectedA && selectedB && selectedA === selectedB) {
        Swal.fire({
          title: "",
          text: "Warehouse A and Warehouse B cannot be the same.",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `,
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `,
          },
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        this.warehouseBTarget.value = "";
      }
    };

    this.warehouseATarget.addEventListener("change", validate);
    this.warehouseBTarget.addEventListener("change", validate);
  }

  fetchTableData() {
    const startDate = this.startDateTarget.value;
    const endDate = this.endDateTarget.value;
    const materialId = this.materialSelectTarget.value;
    const warehouseA = this.warehouseATarget.value;
    let warehouseB = this.warehouseBTarget.value;

    if (!startDate || !endDate || !materialId || !warehouseA) {
      return;
    }

    if (warehouseA && warehouseB && warehouseA === warehouseB) {
      Swal.fire({
        title: "",
        text: "Warehouse A and Warehouse B cannot be the same.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
      this.warehouseBTarget.value = "";
      warehouseB = null;
    }

    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch(`/analytics/get_inventory_shortage_data.json`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        start_date: startDate,
        end_date: endDate,
        material_id: materialId,
        warehouse_a: warehouseA,
        warehouse_b: warehouseB,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        this.populateTable(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  populateSelect(selectElement, options) {
    selectElement.innerHTML = `<option value="" selected>Select Warehouse</option>`;
    options.forEach((option) => {
      const opt = document.createElement("option");
      opt.value = option;
      opt.textContent = option;
      selectElement.appendChild(opt);
    });
  }

  populateTable(data) {
    this.tableBodyTarget.innerHTML = "";

    const inventA = parseFloat(data.invent_a);
    const inventB = parseFloat(data.invent_b);
    const demandQty = parseFloat(data.demand_qty);

    const inventAClass = inventA < demandQty ? "bg-light-danger" : "";
    const inventBClass = 
      inventA < demandQty && (inventA + inventB) < demandQty 
        ? "bg-light-danger" 
        : "";

    const tr = document.createElement("tr");
    tr.innerHTML = `
      <td class="text-center align-middle">${data.material_id}</td>
      <td class="align-middle">${data.material_desc}</td>
      <td class="align-middle">${demandQty}</td>
      <td class="align-middle ${inventAClass}">${inventA}</td>
      <td class="align-middle ${inventBClass}">${inventB}</td>
      <td class="align-middle">${data.comments}</td>
    `;

    this.tableBodyTarget.appendChild(tr);
  }

}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["itemGroupRadio"];
  static values = {
    hasMaterials: Boolean,
  };

  connect() {
    this.handleGenerationTypeChange();
  }

  handleGenerationTypeChange(event) {
    const selectElement = document.getElementById("generation_type");
    const isMaterials = selectElement
      ? selectElement.value === "materials"
      : this.hasMaterialsValue;

    this.itemGroupRadioTargets.forEach((radio) => {
      if (radio.value === "first_planned_order_due_date_item_group_level") {
        const radioWrapper = radio.closest(".w-auto");
        if (isMaterials) {
          radioWrapper.classList.add("d-none");
          const nextRadio = this.findNextVisibleRadio(radio);
          if (nextRadio) {
            nextRadio.checked = true;
          }
        } else {
          radioWrapper.classList.remove("d-none");
        }
      }
    });
  }

  findNextVisibleRadio(currentRadio) {
    const name = currentRadio.name;
    const allRadios = document.getElementsByName(name);
    let found = false;

    for (let radio of allRadios) {
      if (found && !radio.closest(".w-auto").classList.contains("d-none")) {
        return radio;
      }
      if (radio === currentRadio) {
        found = true;
      }
    }

    for (let radio of allRadios) {
      if (!radio.closest(".w-auto").classList.contains("d-none")) {
        return radio;
      }
    }

    return null;
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  loadForecast(event) {
    const forecastSetId = event.target.value;
    const datatableElement = document.querySelector("#forecasts-datatable");

    if (datatableElement) {
      const baseUrl = datatableElement.dataset.source;
      const fullUrl = `${window.location.origin}${baseUrl}`;
      const url = new URL(fullUrl);
      url.searchParams.set("forecast_set_id", forecastSetId);

      // Reload the datatable with the new forecast set
      $(datatableElement).DataTable().ajax.url(url).load();
    }
  }
}

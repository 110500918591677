import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "listModal", "viewsList", "deleteButton"];

  connect() {
    this.loadCustomViews();
  }

  loadCustomViews() {
    let viz_elements = document.getElementsByTagName("tableau-viz");
    let viz;
    if (viz_elements.length > 1) {
      const saveButton = document.querySelector(
        '[data-custom-view-modal-target="saveButton"]',
      );
      const activeTab = saveButton.dataset.activeTab;
      let activeTabPane = document.getElementById(activeTab);
      viz = activeTabPane.querySelector("#tableau-viz");
    } else {
      viz = document.getElementById("tableau-viz");
    }

    let retryCount = 0;
    const maxRetries = 30; // Maximum number of retries

    const checkWorkbook = () => {
      try {
        if (
          viz &&
          viz.workbook &&
          viz.workbook !== undefined &&
          viz.workbook._workbookImpl &&
          viz.workbook._workbookImpl !== undefined
        ) {
          viz.workbook
            .getCustomViewsAsync()
            .then(() => {
              this.fetchCustomViews(viz);
            })
            .catch(() => {
              retryCount++;
              if (retryCount < maxRetries) {
                console.log(
                  `Waiting for workbook initialization... (${retryCount}/${maxRetries})`,
                );
                setTimeout(checkWorkbook, 1000);
              } else {
                console.error(
                  "Failed to initialize workbook after maximum retries",
                );
                this.element.classList.add("d-none");
              }
            });
        } else {
          retryCount++;
          if (retryCount < maxRetries) {
            console.log(
              `Waiting for workbook initialization... (${retryCount}/${maxRetries})`,
            );
            setTimeout(checkWorkbook, 1000);
          } else {
            console.error(
              "Failed to initialize workbook after maximum retries",
            );
            this.element.classList.add("d-none");
          }
        }
      } catch (error) {
        retryCount++;
        if (retryCount < maxRetries) {
          console.log(
            `Waiting for workbook initialization... (${retryCount}/${maxRetries})`,
          );
          setTimeout(checkWorkbook, 1000);
        } else {
          console.error("Failed to initialize workbook after maximum retries");
          this.element.classList.add("d-none");
        }
      }
    };

    checkWorkbook();
  }

  // Separate method for fetching custom views
  fetchCustomViews(viz) {
    viz.workbook
      .getCustomViewsAsync()
      .then((customViews) => {
        // Keep only the first default option
        while (this.selectTarget.options.length > 1) {
          this.selectTarget.remove(1);
        }
        if (customViews && customViews.length > 0) {
          const option = document.createElement("option");
          option.value = "Default";
          option.textContent = "Default";
          this.selectTarget.add(option);
          this.selectTarget.value = "Default";
          this.selectTarget.classList.remove("d-none");
          customViews.forEach((view) => {
            const option = document.createElement("option");
            option.value = view.name;
            option.textContent = `${view.name} ${view.default ? "(Active)" : ""}`;
            this.selectTarget.add(option);
            if (view.default) {
              this.selectTarget.value = view.name;
            }
          });
          this.checkForAlreadySavedViews();
        } else {
          const option = document.createElement("option");
          option.value = "Default";
          option.textContent = "Default";
          this.selectTarget.add(option);
          this.selectTarget.classList.remove("d-none");
          this.selectTarget.value = "Default";
          this.checkForAlreadySavedViews();
        }
      })
      .catch((error) => {
        console.error("Error loading custom views:", error);
        this.element.classList.add("d-none");
      });
  }

  selectView(event) {
    const viewName = event.target.value;
    if (!viewName) return; // Skip if default option is selected

    let viz_elements = document.getElementsByTagName("tableau-viz");
    let viz;
    const saveButton = document.querySelector(
      '[data-custom-view-modal-target="saveButton"]',
    );
    const activeTab = saveButton.dataset.activeTab;
    if (viz_elements.length > 1) {
      let activeTabPane = document.getElementById(activeTab);
      viz = activeTabPane.querySelector("#tableau-viz");
    } else {
      viz = document.getElementById("tableau-viz");
    }
    if (
      viz &&
      viz.workbook !== undefined &&
      viz.workbook._workbookImpl !== undefined
    ) {
      viz.workbook
        .showCustomViewAsync(viewName === "Default" ? null : viewName)
        .then(() => {
          console.log("Custom view loaded successfully");
          const tableauDashboardSettingController =
            this.application.getControllerForElementAndIdentifier(
              this.element,
              "tableau-dashboard-setting",
            );
          if (viz_elements.length > 1) {
            tableauDashboardSettingController.applyFilter({
              target: this.element.querySelector(
                `input[data-active-tab="${activeTab}"]`,
              ),
            });
          }
          this.checkForAlreadySavedViews();
        })
        .catch((error) => {
          console.error("Error selecting custom view:", error);
          alert("Failed to load custom view");
        });
    } else {
      console.log(
        "Workbook not ready for view selection, retrying in 1 second...",
      );
      setTimeout(() => this.selectView(event), 1000);
    }
  }

  checkForAlreadySavedViews() {
    const selectElement = document.querySelector(
      '[data-custom-views-dropdown-target="select"]',
    );
    if (selectElement.value === "Default") {
      this.element
        .querySelector('div[id="div_update_active_option"]')
        .classList.add("d-none");
      this.element
        .querySelector('div[id="div_new_view_option"]')
        .classList.remove("d-none");
      this.element
        .querySelector('div[id="div_new_view_option"]')
        .querySelector('input[id="new_view"]')
        .click();
    } else {
      this.element
        .querySelector('div[id="div_update_active_option"]')
        .classList.remove("d-none");
      this.element
        .querySelector('div[id="div_new_view_option"]')
        .classList.remove("d-none");
      this.element
        .querySelector('div[id="div_update_active_option"]')
        .querySelector('input[id="update_active"]')
        .click();
    }
  }

  async loadViewsList() {
    let viz_elements = document.getElementsByTagName("tableau-viz");
    let viz;
    if (viz_elements.length > 1) {
      const saveButton = document.querySelector(
        '[data-custom-view-modal-target="saveButton"]',
      );
      const activeTab = saveButton.dataset.activeTab;
      let activeTabPane = document.getElementById(activeTab);
      viz = activeTabPane.querySelector("#tableau-viz");
    } else {
      viz = document.getElementById("tableau-viz");
    }

    if (
      viz &&
      viz.workbook !== undefined &&
      viz.workbook._workbookImpl !== undefined
    ) {
      try {
        const customViews = await viz.workbook.getCustomViewsAsync();
        this.viewsListTarget.innerHTML = "";

        // Always add the Default option first
        const defaultDiv = document.createElement("div");
        defaultDiv.classList.add("form-check", "mb-2");

        const defaultInput = document.createElement("input");
        defaultInput.type = "radio";
        defaultInput.name = "defaultView";
        defaultInput.value = null;
        defaultInput.id = "view-default";
        defaultInput.classList.add("form-check-input");

        const defaultLabel = document.createElement("label");
        defaultLabel.classList.add("form-check-label");
        defaultLabel.htmlFor = "view-default";
        defaultLabel.textContent = "Default";

        defaultDiv.appendChild(defaultInput);
        defaultDiv.appendChild(defaultLabel);
        this.viewsListTarget.appendChild(defaultDiv);

        if (customViews && customViews.length > 0) {
          customViews.forEach((view) => {
            const div = document.createElement("div");
            div.classList.add("form-check", "mb-2");

            const input = document.createElement("input");
            input.type = "radio";
            input.name = "defaultView";
            input.value = view.name;
            input.id = `view-${view.name}`;
            input.classList.add("form-check-input");
            if (view.default) {
              input.checked = true;
            }

            const label = document.createElement("label");
            label.classList.add("form-check-label");
            label.htmlFor = `view-${view.name}`;
            label.textContent = `${view.name} ${view.default ? "(Active)" : ""}`;

            div.appendChild(input);
            div.appendChild(label);
            this.viewsListTarget.appendChild(div);
          });

          // If no view is marked as default, check the Default option
          if (
            !this.viewsListTarget.querySelector(
              'input[name="defaultView"]:checked',
            )
          ) {
            defaultInput.checked = true;
          }
        } else {
          // If no custom views, check the Default option
          defaultInput.checked = true;
        }

        // Show/hide delete button based on selection
        this.toggleDeleteButton();

        // Add change event listener for radio buttons
        this.viewsListTarget
          .querySelectorAll('input[name="defaultView"]')
          .forEach((radio) => {
            radio.addEventListener("change", () => this.toggleDeleteButton());
          });
      } catch (error) {
        console.error("Error loading custom views list:", error);
        this.viewsListTarget.innerHTML =
          '<p class="text-danger">Error loading views</p>';
      }
    } else {
      console.log("Workbook not ready, retrying in 1 second...");
      setTimeout(() => this.loadViewsList(), 1000);
    }
  }

  toggleDeleteButton() {
    const selectedView = this.viewsListTarget.querySelector(
      'input[name="defaultView"]:checked',
    );

    if (selectedView && selectedView.value !== "") {
      this.deleteButtonTarget.classList.remove("d-none");
    } else {
      this.deleteButtonTarget.classList.add("d-none");
    }
  }

  async deleteView() {
    const selectedView = this.viewsListTarget.querySelector(
      'input[name="defaultView"]:checked',
    );
    if (!selectedView || selectedView.value === "") return;

    if (
      !confirm(
        `Are you sure you want to delete the view "${selectedView.value}"?`,
      )
    )
      return;

    try {
      let viz_elements = document.getElementsByTagName("tableau-viz");
      let viz;
      if (viz_elements.length > 1) {
        const saveButton = document.querySelector(
          '[data-custom-view-modal-target="saveButton"]',
        );
        const activeTab = saveButton.dataset.activeTab;
        let activeTabPane = document.getElementById(activeTab);
        viz = activeTabPane.querySelector("#tableau-viz");
      } else {
        viz = document.getElementById("tableau-viz");
      }
      const wasDefault =
        selectedView.nextElementSibling.textContent.includes("(Active)");
      const viewName = selectedView.value;
      await viz.workbook.removeCustomViewAsync(selectedView.value);
      $("#customViewsListModal").modal("hide");

      if (wasDefault) {
        // If we deleted the default view, switch to Default
        await viz.workbook.showCustomViewAsync(null);
        $.notify(
          {
            message: `Current active view "${viewName}" was deleted. Reverting back to Default view.`,
          },
          {
            type: "success",
            placement: {
              align: "center",
            },
          },
        );
        await viz.workbook.showCustomViewAsync(null);
      } else {
        $.notify(
          {
            message: `View "${viewName}" deleted successfully`,
          },
          {
            type: "success",
            placement: {
              align: "center",
            },
          },
        );
      }

      // Refresh the views
      await this.loadCustomViews();
      await this.loadViewsList();
    } catch (error) {
      console.error("Error deleting view:", error);
      $.notify(
        {
          message: "Failed to delete view",
        },
        {
          type: "error",
          placement: {
            align: "center",
          },
        },
      );
    }
  }

  async setDefaultView() {
    const selectedRadio = this.viewsListTarget.querySelector(
      'input[name="defaultView"]:checked',
    );
    if (!selectedRadio) {
      $.notify(
        {
          message: "Please select a view to set as default",
        },
        {
          type: "error",
          placement: {
            align: "center",
          },
        },
      );
      return;
    }

    let viz_elements = document.getElementsByTagName("tableau-viz");
    let viz;
    const saveButton = document.querySelector(
      '[data-custom-view-modal-target="saveButton"]',
    );
    const activeTab = saveButton.dataset.activeTab;
    if (viz_elements.length > 1) {
      let activeTabPane = document.getElementById(activeTab);
      viz = activeTabPane.querySelector("#tableau-viz");
    } else {
      viz = document.getElementById("tableau-viz");
    }

    if (
      viz &&
      viz.workbook !== undefined &&
      viz.workbook._workbookImpl !== undefined
    ) {
      try {
        $("#customViewsListModal").modal("hide");
        await viz.workbook.showCustomViewAsync(selectedRadio.value);
        await viz.workbook.setActiveCustomViewAsDefaultAsync();

        const tableauDashboardSettingController =
          this.application.getControllerForElementAndIdentifier(
            this.element,
            "tableau-dashboard-setting",
          );
        if (viz_elements.length > 1) {
          tableauDashboardSettingController.applyFilter({
            target: this.element.querySelector(
              `input[data-active-tab="${activeTab}"]`,
            ),
          });
        }
        // Close modal
        $.notify(
          {
            message: "Default view updated successfully",
          },
          {
            type: "success",
            placement: {
              align: "center",
            },
          },
        );
        // Refresh both lists
        this.loadCustomViews();
        this.loadViewsList();
      } catch (error) {
        console.error("Error setting default view:", error);
        $.notify(
          {
            message: "Failed to set default view",
          },
          {
            type: "error",
            placement: {
              align: "center",
            },
          },
        );
      }
    } else {
      console.log("Workbook not ready, retrying in 1 second...");
      setTimeout(() => this.setDefaultView(), 1000);
    }
  }

  // Optional: Method to refresh the select
  refresh() {
    this.loadCustomViews();
    this.loadViewsList();
  }
}

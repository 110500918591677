import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("Draggable modal controller connected");

    // Use jQuery document ready to ensure jQuery is loaded
    $(document).ready(() => {
      // Listen for any modal being shown
      $(document).on("shown.bs.modal", ".modal", this.initDraggable.bind(this));
    });
  }

  initDraggable() {
    console.log("initDraggable called");
    const modals = document.querySelectorAll(".modal.show .modal-content");

    modals.forEach((modal) => {
      const header = modal.querySelector(".modal-header");
      if (!header) return;

      let isDragging = false;
      let currentX;
      let currentY;
      let initialX;
      let initialY;
      let xOffset = 0;
      let yOffset = 0;

      header.addEventListener("mousedown", startDragging);
      document.addEventListener("mousemove", drag);
      document.addEventListener("mouseup", stopDragging);

      function startDragging(e) {
        initialX = e.clientX - xOffset;
        initialY = e.clientY - yOffset;

        if (e.target.closest(".modal-header")) {
          isDragging = true;
        }
      }

      function drag(e) {
        if (isDragging) {
          e.preventDefault();

          currentX = e.clientX - initialX;
          currentY = e.clientY - initialY;

          xOffset = currentX;
          yOffset = currentY;

          setTranslate(currentX, currentY, modal);
        }
      }

      function stopDragging() {
        isDragging = false;
      }

      function setTranslate(xPos, yPos, el) {
        el.style.transform = `translate3d(${xPos}px, ${yPos}px, 0)`;
      }
    });
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "materialSelect",
    "materialIdField",
    "materialFutureExpiryDateField",
    "extensionToogle",
    "extensionSwitch",
  ];

  populateMaterialIds() {
    this.materialIdFieldTargets.forEach((materialIdField) => {
      materialIdField.value = this.materialSelectTarget.value;
      this.checkExpiryDate();
      this.checkExtensionToogle();
    });
  }

  checkExtensionToogle() {
    this.extensionToogleTargets.forEach((extensionToogleTarget) => {
      const selectedOption =
        this.materialSelectTarget.options[
          this.materialSelectTarget.selectedIndex
        ];
      const extension = selectedOption.dataset.extension;
      if (extension === "true") {
        extensionToogleTarget.classList.remove("d-none");
        extensionToogleTarget.classList.add("d-flex");
      } else {
        extensionToogleTarget.classList.remove("d-flex");
        extensionToogleTarget.classList.add("d-none");
      }
    });
  }

  checkExpiryDate() {
    this.materialFutureExpiryDateFieldTargets.forEach(
      (materialFutureExpiryDateField) => {
        const selectedOption =
          this.materialSelectTarget.options[
            this.materialSelectTarget.selectedIndex
          ];
        // Get the value of the data attribute
        const dataAttribute = selectedOption.getAttribute("data-extension");
        if (dataAttribute == "true") {
          materialFutureExpiryDateField.classList.remove("d-none");
        } else {
          materialFutureExpiryDateField.classList.add("d-none");
        }
      },
    );
  }

  validate(event) {
    this.materialFutureExpiryDateFieldTargets.forEach(
      (materialFutureExpiryDateField) => {
        const futureDateInput = materialFutureExpiryDateField.querySelector(
          ".future_expiration_date",
        );
        const actualDateInput = materialFutureExpiryDateField
          .closest(".row")
          .querySelector(".actual_expiration_date");
        const actualDate = new Date(actualDateInput.value);
        const futureDate = new Date(futureDateInput.value);
        if (futureDate <= actualDate) {
          const errorMessageElement =
            materialFutureExpiryDateField.querySelector(
              "#expiration-date-error",
            );
          errorMessageElement.textContent =
            "Future Expiration Date must be greater than Actual Expiration Date";
          errorMessageElement.style.display = "block";
        } else {
          const errorMessageElement =
            materialFutureExpiryDateField.querySelector(
              "#expiration-date-error",
            );
          errorMessageElement.style.display = "none";
        }
      },
    );
  }

  toggleExpirationExtension(event) {
    const selectedStockStatusOption =
      event.target.options[event.target.selectedIndex];

    const includeInMrp = selectedStockStatusOption.getAttribute(
      "data-include-in-mrp",
    );
    const toogle =
      event.target.parentElement.parentElement.querySelector(
        ".extension-switch",
      );
    if (toogle) {
      if (includeInMrp === "false") {
        toogle.checked = false;
        toogle.disabled = true;
      } else {
        toogle.disabled = false;
      }
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "supplyPlanToggles",
    "rollingSupplyPlanToggles",
    "fpForecastToggles",
    "fpHistoricalToggles",
    "otherMaterialsToggles",
    "forecastAnalysisIndividualMaterialToggles",
    "forecastAnalysisMaterialByFamilyToggles",
  ];

  showTogglesForCurrentTab(event) {
    const currentTab = event.target.dataset.activeTab;
    document
      .querySelectorAll('[data-custom-view-modal-target="saveButton"]')
      .forEach((button) => {
        button.dataset.activeTab = currentTab;
      });
    if (currentTab === "fp-supply-plan") {
      this.supplyPlanTogglesTarget.classList.remove("d-none");
      this.rollingSupplyPlanTogglesTarget.classList.add("d-none");
      this.supplyPlanTogglesTarget.classList.add("d-flex");
      this.rollingSupplyPlanTogglesTarget.classList.remove("d-flex");
    } else if (currentTab === "rolling-supply-plan") {
      this.rollingSupplyPlanTogglesTarget.classList.remove("d-none");
      this.supplyPlanTogglesTarget.classList.add("d-none");
      this.rollingSupplyPlanTogglesTarget.classList.add("d-flex");
      this.supplyPlanTogglesTarget.classList.remove("d-flex");
    } else if (currentTab === "individual-material") {
      this.forecastAnalysisIndividualMaterialTogglesTarget.classList.remove(
        "d-none",
      );
      this.forecastAnalysisMaterialByFamilyTogglesTarget.classList.add(
        "d-none",
      );
      this.forecastAnalysisIndividualMaterialTogglesTarget.classList.add(
        "d-flex",
      );
      this.forecastAnalysisMaterialByFamilyTogglesTarget.classList.remove(
        "d-flex",
      );
    } else if (currentTab === "material-by-family") {
      this.forecastAnalysisMaterialByFamilyTogglesTarget.classList.remove(
        "d-none",
      );
      this.forecastAnalysisIndividualMaterialTogglesTarget.classList.add(
        "d-none",
      );
      this.forecastAnalysisMaterialByFamilyTogglesTarget.classList.add(
        "d-flex",
      );
      this.forecastAnalysisIndividualMaterialTogglesTarget.classList.remove(
        "d-flex",
      );
    }
  }

  updateDashboard(event) {
    let viz_elements = document.getElementsByTagName("tableau-viz");
    let viz;
    if (viz_elements.length > 1) {
      let activeTabPane = document.getElementById(
        event.target.dataset.activeTab,
      );
      viz = activeTabPane.querySelector("#tableau-viz");
    } else {
      viz = document.getElementById("tableau-viz");
    }
    let element_target = event.target.dataset["tableauDashboardSettingTarget"];
    if (element_target == "chartToggle") {
      const result = event.target.checked ? "hide chart" : "show chart";
      viz.workbook.changeParameterValueAsync("show/hide chart", result);
    } else if (element_target == "totalToggle") {
      const result = event.target.checked
        ? "hide grand total"
        : "show grand total";
      viz.workbook.changeParameterValueAsync("show/hide grand total", result);
    }
  }

  applyFilter(event) {
    this.hideToogle();

    const checkAndApplyFilter = () => {
      let viz_elements = document.getElementsByTagName("tableau-viz");
      let viz;

      if (viz_elements.length > 1) {
        let activeTabPane = document.getElementById(
          event.target.dataset.activeTab,
        );
        if (activeTabPane) {
          viz = activeTabPane.querySelector("#tableau-viz");
        }
      } else {
        viz = document.getElementById("tableau-viz");
      }

      if (
        viz &&
        viz.workbook !== undefined &&
        viz.workbook._workbookImpl !== undefined
      ) {
        let element_targets = document.querySelectorAll(
          `input[data-active-tab="${event.target.dataset.activeTab}"]`,
        );

        element_targets.forEach((element_target) => {
          let target_type =
            element_target.dataset.tableauDashboardSettingTarget;

          if (target_type === "chartToggle") {
            const result = element_target.checked ? "hide chart" : "show chart";
            viz.workbook
              .changeParameterValueAsync("show/hide chart", result)
              .catch((error) =>
                console.error("Error changing chart parameter:", error),
              );
          } else if (target_type === "totalToggle") {
            const result = element_target.checked
              ? "hide grand total"
              : "show grand total";
            viz.workbook
              .changeParameterValueAsync("show/hide grand total", result)
              .catch((error) =>
                console.error("Error changing grand total parameter:", error),
              );
          }
        });
        this.activeToogle(viz);
      } else {
        setTimeout(checkAndApplyFilter, 1000);
      }
    };

    checkAndApplyFilter();
  }

  activeToogle(viz) {
    const sheetName = viz.workbook.activeSheet.name;

    const toggleMap = {
      "Future-Shelf Life-Dashboard": "fpForecastToggles",
      "COGS-Dashboard": "fpForecastToggles",
      "Demand vs Production Plan-Dashboard": "fpForecastToggles",
      "Other - Shelf Life-Dashboard": "otherMaterialsToggles",
      "Requirement vs Production-Dashboard": "otherMaterialsToggles",
      "Capacity vs Production-Dashboard": "otherMaterialsToggles",
      "Procurement Cost-Dashboard": "otherMaterialsToggles",
      "Forecast Accuracy-Dashboard": "fpHistoricalToggles",
      "Forecast Error-Dashboard": "fpHistoricalToggles",
      "Demand vs Actual (Unit)- Dashboard": "fpHistoricalToggles",
      "Demand vs Actual (Revenue)- Dashboard": "fpHistoricalToggles",
    };

    const downloadMap = {
      "Future Analysis": "fpForecastToggles",
      "Forecast - KPI- Inventory on hand Dashboard": "fpForecastToggles",
      "Forecast - KPI- Past Due Purchase Orders Dashboard": "fpForecastToggles",
      "Forecast - KPI- upcoming Planned Orders Dashboard": "fpForecastToggles",
      "Forecast - KPI- Excess Stocks Dashboard": "fpForecastToggles",
      "Forecast - KPI- inventory shortage Dashboard": "fpForecastToggles",
      "Past Analysis": "fpHistoricalToggles",
      "Historical - KPI- Inventory on hand Dashboard": "fpHistoricalToggles",
      "Historical - KPI- Past Due Purchase Orders-Dashboard":
        "fpHistoricalToggles",
      "Historical - KPI- upcoming Planned Orders Dashboard":
        "fpHistoricalToggles",
      "Historical - KPI- Excess stocks Dashboard": "fpHistoricalToggles",
      "Historical - KPI- Inventory Shortage Dashboard": "fpHistoricalToggles",
      "Other Dashboard": "otherMaterialsToggles",
      "Other - KPI- Inventory on hand Dashboard": "otherMaterialsToggles",
      "Other - KPI- Past Due Purchase Orders Dashboard":
        "otherMaterialsToggles",
      "Other - KPI- upcoming Planned Orders Dashboard": "otherMaterialsToggles",
      "Other - KPI- Excess Stocks Dashboard": "otherMaterialsToggles",
      "Other - KPI- Inventory Shortage Dashboard": "otherMaterialsToggles",
    };

    const target = toggleMap[sheetName];
    if (target) {
      const toggleDiv = document.querySelector(
        `[data-tableau-dashboard-setting-target="${target}"]`,
      );
      if (toggleDiv) {
        toggleDiv.classList.add("d-flex");
        toggleDiv.classList.remove("d-none");

        const switches = toggleDiv.querySelectorAll(".custom-switch");
        switches.forEach((switchElement) => {
          switchElement.classList.remove("d-none");
        });
      }
    } else {
      const downloadTarget = downloadMap[sheetName];
      if (downloadTarget) {
        const toggleDiv = document.querySelector(
          `[data-tableau-dashboard-setting-target="${downloadTarget}"]`,
        );
        if (toggleDiv) {
          toggleDiv.classList.add("d-flex");
          toggleDiv.classList.remove("d-none");

          const switches = toggleDiv.querySelectorAll(".custom-switch");
          switches.forEach((switchElement) => {
            switchElement.classList.add("d-none");
          });
        }
      }
    }
  }

  hideToogle() {
    document.querySelectorAll(".dashboard-toggle").forEach((element) => {
      element.classList.add("d-none");
      element.classList.remove("d-flex");
    });
  }

  download(event) {
    event.preventDefault();

    const downloadType = event.currentTarget.getAttribute("data-value");
    let viz_elements = document.getElementsByTagName("tableau-viz");
    let viz;
    if (viz_elements.length > 1) {
      let activeTabPane = document.getElementById(
        event.target.dataset.activeTab,
      );
      viz = activeTabPane.querySelector("#tableau-viz");
    } else {
      viz = document.getElementById("tableau-viz");
    }

    if (viz) {
      switch (downloadType) {
        case "crosstab":
          viz.displayDialogAsync("export-cross-tab");
          break;
        case "pdf":
          viz.displayDialogAsync("export-pdf");
          break;
        case "ppt":
          viz.displayDialogAsync("export-powerpoint");
          break;
        case "image":
          viz.exportImageAsync();
          break;
        default:
          console.error("Unsupported download type");
      }
    } else {
      console.error("Tableau Viz not initialized");
    }
  }
}

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
ActiveStorage.start();

import { Application } from "@hotwired/stimulus";
import * as Honeybadger from "@honeybadger-io/js";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import Swal from "sweetalert2";
import Sortable from "sortablejs";

Honeybadger.configure({
  apiKey: "hbp_yHLmI6rkgf0szBwxNZctUSrf05BZT11OVuk4",
  environment: process.env.RAILS_ENV || "development",
  revision: process.env.HEROKU_SLUG_COMMIT || "master",
});

const application = Application.start();

// Set up error handler
application.handleError = (error, message, detail) => {
  console.warn(message, detail);
  Honeybadger.notify(error);
};

const context = require.context("../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

import "./select2";
import LocalTime from "local-time";
import bsCustomFileInput from "bs-custom-file-input";

document.addEventListener("turbo:load", function () {
  LocalTime.start();
  bsCustomFileInput.init();

  document.body.addEventListener("keydown", function (e) {
    if (e.target && e.target.type == "number") {
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();
      }
    }
  });
});

document.addEventListener("turbo:before-cache", function () {
  // When clicked on one of the hierarchy node the modal will still be open(while loading next page).
  // Making sure the modal is already closed on new page.
  $("#hierarchyModal").modal("hide");
});

// disable mousewheel on a input number field when in focus
document.addEventListener("wheel", function (event) {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
});

var tooltipWhiteList = $.fn.tooltip.Constructor.Default.whiteList;
// To allow table elements
tooltipWhiteList.table = ["class"];
tooltipWhiteList.thead = [];
tooltipWhiteList.tbody = [];
tooltipWhiteList.tr = [];
tooltipWhiteList.th = [];
tooltipWhiteList.td = [];

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag "rails.png" %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context("../images", true)
// const imagePath = (name) => images(name, true)

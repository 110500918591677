import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["alert"];

  connect() {
    const formattedTime = this.getFormattedTime();
    const url = new URL(this.element.href);
    url.searchParams.append("time", formattedTime);
    this.element.href = url.toString();
  }

  // getFormattedTime() {
  //   const currentTime = new Date();
  //   const options = {
  //     year: 'numeric',
  //     month: 'short',
  //     day: 'numeric',
  //     hour: 'numeric',
  //     minute: 'numeric',
  //     hour12: true,
  //   };
  //   return currentTime.toLocaleString(undefined, options);
  // }

  getFormattedTime() {
    const currentTime = new Date();
    const day = currentTime.getDate().toString().padStart(2, "0");
    const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
    const year = currentTime.getFullYear().toString();
    const hour = currentTime.getHours().toString().padStart(2, "0");
    const minute = currentTime.getMinutes().toString().padStart(2, "0");
    return `${year}${month}${day},${hour}${minute}`;
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["overrideToggle"];

  handleSafetyStockTypeChange(event) {
    const safetyStockType = event.target.value;

    if (safetyStockType === "fixed") {
      this.overrideToggleTarget.classList.add("d-none");
    } else {
      this.overrideToggleTarget.classList.remove("d-none");
    }
  }
}

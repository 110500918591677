import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "submitButton"];

  submit(event) {
    event.preventDefault();

    const formData = new FormData(this.formTarget);

    const data = {};
    formData.forEach((value, key) => {
      const keys = key.match(/[^[\]]+/g);
      let current = data;

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = value;
        } else {
          current[key] = current[key] || {};
          current = current[key];
        }
      });
    });

    this.sendRequest(data, false);
  }

  sendRequest(data, confirmUpdate) {
    data.stock = data.stock || {};
    data.stock.confirm_expiration_date_update = confirmUpdate;
    data.stock.from_update = true;

    this.submitButtonTarget.disabled = true;

    fetch(this.formTarget.action, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.success) {
          Swal.fire({
            title: "Stock updated successfully!",
            text: "",
            icon: "success",
            showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `,
            },
            hideClass: {
              popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `,
            },
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-secondary",
            },
          }).then(() => {
            window.location.href = responseData.redirect_url;
          });
        } else if (responseData.confirm_required) {
          Swal.fire({
            title: "",
            text: responseData.message,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `,
            },
            hideClass: {
              popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `,
            },
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-secondary",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              this.sendRequest(data, true);
            }
          });
        } else if (!responseData.success) {
          this.displayErrors(responseData.error_fields);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An unexpected error occurred. Please try again.");
      })
      .finally(() => {
        this.submitButtonTarget.disabled = false;
      });
  }

  displayErrors(errorFields) {
    this.clearErrors();

    for (const [field, messages] of Object.entries(errorFields)) {
      const input = this.findInputByName(field);
      if (input) {
        this.addErrorMessage(input, messages[0]);
        input.classList.add("is-invalid");
      }
    }
  }

  clearErrors() {
    this.element.querySelectorAll(".invalid-feedback").forEach((error) => error.remove());
    this.element.querySelectorAll(".is-invalid").forEach((input) => input.classList.remove("is-invalid"));
  }

  findInputByName(fieldName) {
    return this.formTarget.querySelector(`[name='stock[${fieldName}]']`);
  }

  addErrorMessage(input, message) {
    const errorDiv = document.createElement("div");
    errorDiv.textContent = message;
    errorDiv.classList.add("invalid-feedback");

    if (input.classList.contains("select2-hidden-accessible")) {
      const select2Container = input.nextElementSibling;
      if (select2Container && select2Container.classList.contains("select2")) {
        select2Container.insertAdjacentElement("afterend", errorDiv);
        select2Container.classList.add("is-invalid");
        return;
      }
    }

    const inputGroup = input.closest(".input-group");

    if (inputGroup) {
      inputGroup.appendChild(errorDiv);
    } else {
      input.insertAdjacentElement("afterend", errorDiv);
    }

    input.classList.add("is-invalid");
  }

}

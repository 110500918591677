import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  // static values = { url: String };
  static targets = ["sortableList", "hiddenField"];

  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 350,
      ghostClass: "bg-gray-200",
      onEnd: this.onEnd.bind(this),
      handle: "[data-sortable-handle]",
      dataIdAttr: "data-sortable-id",
    });

    this.setSortedPositionArray();
  }

  disconnect() {
    this.sortable.destroy();
  }

  onEnd(event) {
    this.setSortedPositionArray();
  }

  setSortedPositionArray() {
    const orderedItems = this.sortable.toArray();

    const positions = orderedItems.map((id, index) => ({
      id: id,
      position: index,
    }));

    this.hiddenFieldTarget.value = JSON.stringify(positions);
  }

  // to store poistion automatically without submit button
  //
  // onEnd(event) {
  //   const { newIndex, item } = event;
  //   const url = item.dataset["sortableUrl"]
  //   const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  //   fetch(url, {
  //     method: "PUT",
  //     body: JSON.stringify({ position: newIndex }),
  //     headers: {
  //       "Content-type": "application/json; charset=UTF-8",
  //       "X-CSRF-Token": csrfToken,
  //     },
  //   });
  // }
}

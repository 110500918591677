import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["requiredInput", "submitButton"];

  connect() {
    this.activate();
  }

  activate() {
    if (this.requiredInputTarget.value != "") {
      this.submitButtonTargets.forEach(
        (submitButtonTarget) => (submitButtonTarget.disabled = false),
      );
    } else {
      this.submitButtonTargets.forEach(
        (submitButtonTarget) => (submitButtonTarget.disabled = true),
      );
    }
  }

  activateGenerationType(event) {
    const selectedOption = event.target.selectedOptions[0];
    if (selectedOption.dataset.immediateSubmit === "true") {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select"];

  connect() {
    this.updateSelects(this.selectTargets[0].value);
  }

  sync(event) {
    const value = event.target.value;
    this.updateSelects(value);
  }

  updateSelects(value) {
    this.selectTargets.forEach((select) => {
      $(select).val(value).trigger("change");
    });
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "materialId",
    "manufacturingTime",
    "manufacturingTimePeriod",
    "processingTime",
    "processingTimePeriod",
    "transitTime",
    "transitTimePeriod",
    "qualityInspectionTime",
    "qualityInspectionTimePeriod",
    "purchasingUom",
    "lotSize",
    "lotSizeType",
    "roundingValue",
  ];

  async fetchValues(event) {
    const materialId = event.target.value;
    if (!materialId) return;

    try {
      const response = await fetch(`/materials/${materialId}/fetch_values`);
      const data = await response.json();

      if (data) {
        // Populate lead times and their periods
        this.manufacturingTimeTarget.value = data.manufacturing_time || 0;
        this.setSelectValue(
          this.manufacturingTimePeriodTarget,
          data.manufacturing_time_period,
        );

        this.processingTimeTarget.value = data.processing_time || 0;
        this.setSelectValue(
          this.processingTimePeriodTarget,
          data.processing_time_period,
        );

        this.transitTimeTarget.value = data.transit_time || 0;
        this.setSelectValue(
          this.transitTimePeriodTarget,
          data.transit_time_period,
        );

        this.qualityInspectionTimeTarget.value =
          data.quality_inspection_time || 0;
        this.setSelectValue(
          this.qualityInspectionTimePeriodTarget,
          data.quality_inspection_time_period,
        );

        // Set purchasing UoM (select input)
        this.setSelectValue(
          this.purchasingUomTarget,
          data.purchasing_unit_of_measure_id,
        );

        // Set lot size type (radio input)
        this.setRadioValue(data.lot_size_type);

        // Set lot size
        this.lotSizeTarget.value = data.lot_size || 0;
        this.roundingValueTarget.value = data.rounding_value || 0;

        // Dispatch event for any other controllers that need to know values changed
        this.dispatch("valuesLoaded");
      }
    } catch (error) {
      console.error("Error fetching material values:", error);
    }
  }

  // Helper method to set select input values
  setSelectValue(selectElement, value) {
    if (value && selectElement.querySelector(`option[value="${value}"]`)) {
      selectElement.value = value;
      // Trigger change event for any dependent listeners
      selectElement.dispatchEvent(new Event("change", { bubbles: true }));
    } else {
      selectElement.selectedIndex = 0; // Set to first option if value not found
    }
  }

  // Helper method to set radio input values
  setRadioValue(value) {
    if (value) {
      this.lotSizeTypeTargets.forEach((radio) => {
        if (radio.value === value) {
          radio.click();
        }
      });
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["percentageForm", "alternateForm"];

  connect() {
    this.toggle();
  }

  toggle() {
    const allocationType = this.element.querySelector(
      'input[name="material[allocation_type]"]:checked',
    ).value;
    this.showOrHideForms(allocationType);
  }

  showOrHideForms(allocationType) {
    if (allocationType === "percentage") {
      this.percentageFormTarget.style.display = "block";
      this.alternateFormTarget.style.display = "none";

      this.percentageFormTarget.querySelectorAll("input").forEach((input) => {
        input.removeAttribute("disabled");
        input.setAttribute("required", true);
      });

      this.alternateFormTarget.querySelectorAll("input").forEach((input) => {
        input.setAttribute("disabled", true);
        input.removeAttribute("required");
      });
    } else if (allocationType === "alternate") {
      this.percentageFormTarget.style.display = "none";
      this.alternateFormTarget.style.display = "block";

      this.percentageFormTarget.querySelectorAll("input").forEach((input) => {
        input.setAttribute("disabled", true);
        input.removeAttribute("required");
      });

      this.alternateFormTarget.querySelectorAll("input").forEach((input) => {
        input.removeAttribute("disabled");
        input.setAttribute("required", true);
      });
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["viewInput", "select"];

  connect() {
    // Initialize the form based on default selection
    this.toggleViewInput({
      target: this.element.querySelector('input[name="view_option"]:checked'),
    });
  }

  toggleViewInput(event) {
    const viewNameInput = this.viewInputTarget.querySelector(
      'input[name="new_view_name"]',
    );

    if (event.target.value === "new_view") {
      this.viewInputTarget.classList.remove("d-none");
      viewNameInput.required = true;
      viewNameInput.disabled = false;
    } else {
      this.viewInputTarget.classList.add("d-none");
      viewNameInput.required = false;
      viewNameInput.disabled = true;
    }
  }

  async saveView(event) {
    const option = this.element.querySelector(
      'input[name="view_option"]:checked',
    ).value;
    let viz_elements = document.getElementsByTagName("tableau-viz");
    let viz;
    if (viz_elements.length > 1) {
      let activeTabPane = document.getElementById(
        event.target.dataset.activeTab,
      );
      viz = activeTabPane.querySelector("#tableau-viz");
    } else {
      viz = document.getElementById("tableau-viz");
    }

    try {
      if (option === "new_view") {
        const viewName = this.viewInputTarget.querySelector(
          'input[name="new_view_name"]',
        ).value;
        if (!viewName) {
          alert("Please enter a view name");
          return;
        }
        await viz.workbook.saveCustomViewAsync(viewName);
        // Close modal after successful save
        const selectElement = document.querySelector(
          '[data-custom-views-dropdown-target="select"]',
        );

        // Create and insert the new option at the top (after the Default option)
        const newOption = document.createElement("option");
        newOption.value = viewName;
        newOption.textContent = viewName;
        selectElement.insertBefore(newOption, selectElement.options[1]);

        // Set as selected
        selectElement.value = viewName;
      } else {
        const active = viz.workbook.activeCustomView;
        if (active) {
          await viz.workbook.saveCustomViewAsync(active.name);
          console.log(`Saved the active custom view: '${active.name}' again`);
        } else {
          $.notify(
            {
              message: "No active custom view to update",
            },
            {
              type: "error",
              placement: {
                align: "center",
              },
            },
          );
          return;
        }
      }

      $(this.element).modal("hide");

      $.notify(
        {
          message: "Custom view saved successfully",
        },
        {
          type: "success",
          placement: {
            align: "center",
          },
        },
      );
    } catch (error) {
      console.error("Error saving custom view:", error);
      $.notify(
        {
          message: "Failed to save custom view",
        },
        {
          type: "error",
          placement: {
            align: "center",
          },
        },
      );
    }
  }
}

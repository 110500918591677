import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "dateField"];

  toggleDateField() {
    if (this.checkboxTarget.checked) {
      const originalValue = this.dateFieldTarget
        .getAttribute("data-original-value")
        .replace(/"/g, "");
      this.dateFieldTarget.value = originalValue;
      this.dateFieldTarget.disabled = true;
    } else {
      this.dateFieldTarget.disabled = false;
    }
  }

  toggle() {
    this.toggleDateField();
  }
}
